import axios from 'axios';
import { executeCaptcha } from './captcha';

const ACTIVE_POST_MESSAGE_CLASS = 'competition-form__post-message--show';
const ACTIVE_FORM_CLASS = 'competition-form--show';
const DEACTIVE_POLL_CLASS = 'competition-poll--hide';
const EXPIRATION_DATE_WATCHER_TIMEOUT = 1000;

/** @type {HTMLFormElement} */
export const formElement = document.querySelector('[data-form]');

/**
 * there should by only one poll on page, but for prevetion the last is taken
 * @type {NodeListOf<HTMLFormElement>}
 */
const pollFormElements = document.querySelectorAll('[data-poll]');
const pollFormElement = pollFormElements[pollFormElements.length - 1];
const pollContainer = pollFormElement ? pollFormElement.parentElement : null;

/** @type {HTMLElement} */
const successMessageElement = document.querySelector('[data-form-success]');

/** @type {HTMLElement} */
const errorMessageElement = document.querySelector('[data-form-error]');

/** @type {{ formStartDate: Date, formEndDate: Date }} */
const formDates = (() => {
    if (!formElement) return null;

    const formStartTime = formElement.getAttribute('data-start');
    const formEndTime = formElement.getAttribute('data-end');

    const [startHours, startMinutes] = formStartTime.split(':').map((value) => parseInt(value, 10));
    const formStartDate = new Date();
    formStartDate.setHours(startHours, startMinutes, 0);

    const [endHours, endMinutes] = formEndTime.split(':').map((value) => parseInt(value, 10));
    const formEndDate = new Date();
    formEndDate.setHours(endHours, endMinutes, 0);

    return { formStartDate, formEndDate };
})();

/** @returns {Boolean} */
const isActiveForm = () => {
    const { formStartDate, formEndDate } = formDates;
    const now = new Date();

    return formStartDate <= now && now < formEndDate;
};

const watchExpirationDate = async () => {
    // pause runtime
    await new Promise((resolve) => {
        setTimeout(resolve, EXPIRATION_DATE_WATCHER_TIMEOUT);
    });

    if (isActiveForm()) {
        requestAnimationFrame(watchExpirationDate);
    } else {
        formElement.parentElement.removeChild(formElement);

        if (pollContainer) {
            pollContainer.parentElement.removeChild(pollContainer);
        }
    }
};

const configureFormExpiration = () => requestAnimationFrame(watchExpirationDate);

/**
 * @param {HTMLInputElement} input
 * @returns {String}
 */
const getValidationErrorType = (input) => {
    const { validity } = input;
    const isRadio = input.type === 'radio';
    const isCheckbox = input.type === 'checkbox';

    const emptyErrorType = isCheckbox || isRadio ? input.type : 'empty';

    if (validity.valueMissing) return emptyErrorType;
    if (validity.patternMismatch) return 'pattern';
    if (validity.typeMismatch) return 'format';

    return '';
};

/** @param {HTMLLabelElement} label */
const configureBoundElement = (label) => {
    const boundElementName = label.getAttribute('data-bind');
    const boundElements = document.querySelectorAll(`[data-${boundElementName}]`);

    if (boundElements.length && boundElementName === 'poll') {
        [...boundElements].forEach((boundElement) => {
            boundElement.setAttribute('data-invalid', 'radio');
        });
    }
};

/** @param {HTMLInputElement} input */
const setValidationState = (input) => {
    /** @type {HTMLLabelElement} */
    const label = input.parentElement;

    if (label.tagName !== 'LABEL') {
        throw Error('Input element should be wrapped by Label element for validation messages');
    }

    if (input.validity.valid) {
        label.removeAttribute('data-invalid');
    } else {
        label.setAttribute('data-invalid', getValidationErrorType(input));

        configureBoundElement(label);
    }
};

/** @param {Event} event */
const validateInput = (event) => {
    event.preventDefault();

    /** @type {HTMLInputElement} */
    const input = event.currentTarget;

    setValidationState(input);

    if (input.type === 'checkbox' || input.type === 'radio') {
        input.addEventListener('change', validateInput);
    } else {
        input.addEventListener('blur', validateInput);
    }
};

const validateForm = () => {
    [...formElement.elements].forEach((input) => {
        input.addEventListener('invalid', validateInput);
    });
};

const bindPollToForm = () => {
    [...pollFormElement.elements].forEach((element) => {
        element.addEventListener('change', (event) => {
            event.preventDefault();

            pollFormElement.removeAttribute('data-invalid');

            /** @type {HTMLInputElement} */
            const boundInput = formElement.elements[element.name];

            if (boundInput) {
                boundInput.value = element.value;
            }
        });
    });
};

/**
 * @param {Boolean} isPassed
 */
const showFormState = (isPassed) => {
    if (isPassed && successMessageElement) {
        successMessageElement.classList.add(ACTIVE_POST_MESSAGE_CLASS);
    } else if (errorMessageElement) {
        errorMessageElement.classList.add(ACTIVE_POST_MESSAGE_CLASS);
    }

    formElement.classList.remove(ACTIVE_FORM_CLASS);
    pollContainer.classList.add(DEACTIVE_POLL_CLASS);
};

const hideFormState = () => {
    if (successMessageElement) {
        successMessageElement.classList.remove(ACTIVE_POST_MESSAGE_CLASS);
    }

    if (errorMessageElement) {
        errorMessageElement.classList.remove(ACTIVE_POST_MESSAGE_CLASS);
    }
};

const configurePhoneField = () => {
    const phoneField = formElement.elements.phone;

    if (!phoneField) return;

    phoneField.addEventListener('input', (event) => {
        event.preventDefault();

        // remove more than one space
        phoneField.value = phoneField.value.replace(/ +/g, ' ');
    });
};

/** @param {FormData} formData */
const formatZip = (formData) => {
    const zipField = formElement.elements.zip;

    if (!zipField) return;

    // remove space
    const zipValue = zipField.value.replace(/ +/g, '');

    formData.append('zip', zipValue);
};

/** @param {FormData} formData */
const formatPhone = (formData) => {
    const phoneField = formElement.elements.phone;

    if (!phoneField) return;

    const phoneValue = phoneField.value.replace(/ +/g, '');

    if (!phoneValue.startsWith('+420')) {
        formData.append('phone', `+420${phoneValue}`);
    } else {
        formData.append('phone', phoneValue);
    }
};

/** @param {FormData} formData */
const formatFormData = (formData) => {
    formatZip(formData);
    formatPhone(formData);
};

/** @returns {Promise<Response>} */
const sendForm = async () => {
    const formData = new FormData(formElement);

    formatFormData(formData);

    return axios.post('/', formData);
};

/** @returns {Promise<Response>} */
const sendCaptcha = async () => {
    const formData = new FormData();

    formData.append('check', 'true');
    formData.append('recaptcha_response', formElement.elements.recaptcha_response.value);

    return axios.post('/', formData);
};

/** @param {Event} event */
const processForm = async (event) => {
    event.preventDefault();

    // disable multiple form submiting
    formElement.removeEventListener('submit', processForm);
    formElement.addEventListener('submit', (innerEvent) => innerEvent.preventDefault());

    hideFormState();

    /** @type {HTMLButtonElement} */
    const formSubmitButton = formElement.querySelector('[data-submit]');

    if (formSubmitButton) {
        formSubmitButton.classList.add('competition-form__button--loading');
    }

    try {
        const captchaResponse = await sendCaptcha();

        if (captchaResponse.data.err !== 'ok') {
            throw new Error('wrong captcha');
        }

        const formResponse = await sendForm();

        if (formResponse.status !== 200) {
            throw new Error(`wrong request url: ${formResponse.url}`);
        }

        showFormState(true);
        executeCaptcha();
    } catch (error) {
        showFormState(false);
        throw new Error(error.message);
    } finally {
        if (formSubmitButton) {
            formSubmitButton.classList.remove('competition-form__button--loading');
        }

        formElement.reset();
        pollFormElement.reset();

        // enable submiting form after sending
        formElement.addEventListener('submit', processForm);
    }
};

const trimFormFields = () => {
    [...formElement.elements].forEach((element) => {
        element.addEventListener('blur', (event) => {
            event.preventDefault();

            element.value = element.value.trim();
        });
    });
};

export const form = () => {
    if (!formElement) return;

    if (pollFormElement) {
        bindPollToForm();
        configureFormExpiration();
    }

    trimFormFields();
    validateForm();
    configurePhoneField();

    formElement.addEventListener('submit', processForm);
};
