const menuElement = document.querySelector('[data-menu]');
const menuToggleButton = document.querySelector('[data-open-menu]');

/** @param {Event} event */
const toggleMenu = (event) => {
    event.preventDefault();

    const isOpenMenu = menuElement.getAttribute('data-state') === 'open';
    const state = isOpenMenu ? 'close' : 'open';

    menuElement.setAttribute('data-state', state);
};

export const menu = () => {
    if (!menuElement || !menuToggleButton) return;

    menuToggleButton.addEventListener('click', toggleMenu);
};
