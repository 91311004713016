import { initGallery } from './gallery';

const POPUP_OPEN_CLASS = 'overlay--show';

const popupElement = document.querySelector('[data-popup]');
const openButtons = document.querySelectorAll('[data-open-popup]');
const articles = popupElement ? popupElement.querySelectorAll('[data-article]') : null;

const isPopupOpen = () => popupElement.classList.contains(POPUP_OPEN_CLASS);

/** @param {Number} id */
const showArticle = (id) => {
    if (!articles) return;

    [...articles].forEach((article) => article.classList.remove('article--show'));

    const currentArticle = [...articles].find(
        (article) => article.getAttribute('data-article') === id,
    );

    if (!currentArticle) return;

    currentArticle.classList.add('article--show');

    const galleryImagesCount = currentArticle.querySelectorAll('.gallery__item').length;

    if (galleryImagesCount > 1) {
        initGallery(id);
    }
};

/** @param {Event} event */
const openPopup = (event) => {
    if (isPopupOpen()) return;

    event.preventDefault();

    const articleId = event.currentTarget.getAttribute('data-article-id');

    popupElement.classList.add(POPUP_OPEN_CLASS);
    showArticle(articleId);
};

const configurePopupOpening = () => {
    [...openButtons].forEach((button) => button.addEventListener('click', openPopup));
};

/** @param {Event} event */
const closePopup = (event) => {
    if (!isPopupOpen()) return;

    event.preventDefault();

    popupElement.classList.remove(POPUP_OPEN_CLASS);
};

const configurePopupClosing = () => {
    const closeButton = popupElement.querySelector('[data-close-popup]');
    const closeArea = popupElement.querySelector('[data-popup-inner]');

    if (closeButton) {
        closeButton.addEventListener('click', closePopup);
    }

    closeArea.addEventListener('click', (event) => {
        if (event.target === event.currentTarget) {
            event.preventDefault();

            closePopup(event);
        }
    });

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' && isPopupOpen()) {
            closePopup(event);
        }
    });
};

export const popup = () => {
    if (!popupElement) return;

    configurePopupOpening();
    configurePopupClosing();
};
