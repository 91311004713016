const BOTTOM_ELEMENTS_ORDER = ['prize', 'poll', 'form', 'winners'];

const articles = document.querySelectorAll('[data-article]');

/** @param {HTMLElement} article */
const moveElementsToBottom = (article) => {
    const articleBottom = article.querySelector('[data-article-bottom-target]');

    BOTTOM_ELEMENTS_ORDER.forEach((elementName) => {
        const currentElement = article.querySelector(`[data-article-bottom="${elementName}"]`);

        if (currentElement) {
            articleBottom.appendChild(currentElement);
        }
    });
};

export const article = () => {
    [...articles].forEach(moveElementsToBottom);
};
