/** @param {Number} articleId */
export const initGallery = (articleId) => {
    if (window.$) {
        const currentGallery = window.$(`[data-article="${articleId}"] .gallery__list`);

        currentGallery.owlCarousel({
            items: 1,
            lazyLoad: true,
            loop: true,
            margin: 5,
            nav: true,
            dots: false,
            navText: ['Předchozí obrázek', 'Následující obrázek'],
            navClass: [
                'gallery__button gallery__button--prev',
                'gallery__button gallery__button--next',
            ],

            onInitialize() {
                currentGallery[0].classList.add('gallery__list--show');
            },
        });
    }
};
