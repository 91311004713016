export const sticker = () => {
    const ACTIVE_STICKER_COMPETE_CLASS = 'main-prize__sticker-compete--visible';
    const MIN_DATE = new Date(Date.parse('03 Dec 2021 17:01:00 GMT+0100'));
    const MAX_DATE = new Date(Date.parse('06 Dec 2021 04:59:00 GMT+0100'));
    const CURRENT_DATE = new Date();

    if (MIN_DATE <= CURRENT_DATE && CURRENT_DATE <= MAX_DATE) {
        document.querySelector('.prize-sticker').remove();
        document
            .querySelector('.main-prize__sticker-compete')
            .classList.add(ACTIVE_STICKER_COMPETE_CLASS);
        document.querySelector('.page-main').classList.add('page-main--moved-down');
    }
};
