import { captcha } from './modules/captcha';
import { popup } from './modules/popup';
import { form } from './modules/form';
import { menu } from './modules/menu';
import { article } from './modules/article';
import { sticker } from './modules/sticker';

captcha();
popup();
form();
menu();
article();
sticker();
