const CAPTCHA_CODE = '6LftDb4UAAAAAD6vNModOK0t1mK4rgCTzDx8fq6W';
const CAPTCHA_RELOAD_INTERVAL = 60000;

const { grecaptcha } = window;
const responseInputs = document.querySelectorAll('[data-captcha-response]');

/** @param {String} token */
const updateResponseInputs = (token) => {
    [...responseInputs].forEach((input) => {
        input.value = token;
    });
};

export const executeCaptcha = async () => {
    const token = await grecaptcha.execute(CAPTCHA_CODE);

    updateResponseInputs(token);
};

export const captcha = () => {
    grecaptcha.ready(() => {
        executeCaptcha();

        setInterval(executeCaptcha, CAPTCHA_RELOAD_INTERVAL);
    });
};
